<template>
  <div>
    <div class="app-container">
      <div class="outer">
        <search-panel
          flex
          show-btn
          :is-entry="false"
          @getList="getList"
        >
          <div>
            <p>门店名称</p>
            <el-input
              v-model.trim="name"
              clearable
              size="mini"
              placeholder="请输入门店名称"
            />
          </div>
          <el-button
            slot="button-right"
            type="primary"
            size="mini"
            @click="toDetail('add')"
          >
            新增门店
          </el-button>
        </search-panel>

        <el-table
          v-loading="loading"
          :data="tableData"
          border
          :header-cell-style="{
            background: '#D9DDE1',
            color: '#0D0202',
            'font-weight': 400
          }"
          style="width: 100%"
          element-loading-text="拼命加载中"
        >
          <el-table-column
            prop="id"
            label="ID"
            width="50"
          />
          <el-table-column
            prop="name"
            label="门店名字"
          />
          <el-table-column
            prop="address"
            label="门店地址"
          />
          <el-table-column
            prop="moblie"
            label="手机电话"
            width="120"
          />
          <el-table-column label="营业时间">
            <template slot-scope="scope">
              <div
                v-for="(item,index) in scope.row.parseBusinessHours"
                :key="index"
              >
                {{ item.days }}（{{ item.times }}）
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="150"
          >
            <template slot-scope="scope">
              {{ dayFormat(scope.row.createTime) }}
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            fixed="right"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="operation('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                :style="{color: 'red'}"
                @click="operation('delete', scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */
import dayjs from 'dayjs';

export default {
  name: 'ActivityList',
  data() {
    return {
      name: '',
      tableData: [],
      loading: false,
      suppliersId: '',
      storeId: ''
    };
  },
  watch: {
  },
  created() {
    let userInfo = localStorage.getItem('userInfo');
    this.suppliersId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
    this.storeId = userInfo && JSON.parse(userInfo).storeList && JSON.parse(userInfo).storeList[0] ? JSON.parse(userInfo).storeList[0].storeId : localStorage.getItem('defaultStoreId');
    this.getList();
  },
  mounted() {

  },
  methods: {
    toDetail(type) {
      if (type === 'add') {
        this.$router.push({
          path: '/nb/offlineStore/detail',
          query: {
            type: 'add',
            suppliersId: this.suppliersId,
            storeId: this.storeId
          }
        })
      }
    },
    operation(type, row) {
      if (type === 'delete') {
        this.deleteShop(row.id)
      } else if (type === 'edit') {
        sessionStorage.setItem('offlineStoreInfo', JSON.stringify(row))
        this.$router.push({
          path: '/nb/offlineStore/detail',
          query: {
            type: 'edit',
            suppliersId: this.suppliersId,
            storeId: this.storeId
          }
        })
      }
    },
    getList() {
      // index为页面当前页数
      let _this = this;
      _this.loading = true;
      _this.$axios
        .get(_this.$api.tradeShop.list, {
          params: {
            suppliersId: this.suppliersId,
            name: this.name ? this.name : undefined
          }
        })
        .then(function(res) {
          if (res.code === 0 && res.data) {
            const { size, total, current, pages } = res.data;
            _this.tableData = res.data;
            _this.tableData.forEach(item => {
              item.businessHours && _this.$set(item, 'parseBusinessHours', JSON.parse(item.businessHours))
            })
            console.log(_this.tableData)
          } else {
            _this.tableData = [];
          }
          _this.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    deleteShop(id) {
      let _this = this;
      this.$confirm('是否确认删除门店?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.loading = true;
        _this.$axios
          .get(_this.$api.tradeShop.delete, {
            params: {
              id
            }
          })
          .then(function(res) {
            _this.loading = false;
            if (res.code === 0) {
              _this.$message({ type: 'success', message: '操作成功！' })
              _this.getList();
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }).catch(() => {
      });
    },
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    }
  }
};
</script>
<style lang="scss" scoped>

</style>

<style lang="scss">
.goods_message {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  .left {
    margin: 10px 15px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .right {
    .title {
      font-weight: 700;
      margin: 10px 0;
    }
    .id {
      color: #999;
    }
  }
}
</style>
